.download-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.download-card {
    margin: 10px;
  }
  
  @media (max-width: 768px) {
    .download-cards-container {
      flex-direction: column;
      align-items: center;
    }
  }