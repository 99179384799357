.profile-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.profile-card {
  margin: 10px;
}
  
  @media (max-width: 768px) {
    .profile-cards-container {
      flex-direction: column;
      align-items: center;
    }
  }